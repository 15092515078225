// => 合同模块 
import http from "./http"
/**  @namespace: {合同模板列表},  @param: { token }  */
export const $apiContractTempList = (data) => http({ method: "get", url: "/web/contract/template/list", params: data })
/**  @namespace: {改变订单的合同类型},  @param: { token }  */
export const $apiSetContractTemp = (data) => http({ method: "get", url: "/web/contract/Revise", params: data })
/**  @namespace: {编辑合同信息},  @param: { token }  */
export const $apiChangeContract = (data) => http({ method: "post", url: "/Goods/Web/upContract", params: data })
/**  @namespace: {商家确认合同},  @param: { token,id }  */
export const $apiSubContract = (data) => http({ method: "post", url: "/Goods/Web/upContractConfirm", params: data })

